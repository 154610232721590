<!--
 * @Descripttion: 预约演示
 * @version: 
 * @Author: alex.yang
 * @Date: 2024-05-11 17:48:39
 * @LastEditors: alex.yang
 * @LastEditTime: 2024-08-13 10:40:26
-->
<template>
    <div class="demonstrationPage">
        <el-dialog
            :visible.sync="show"
            width="700px"
            @close="clickDemoClose"
            :show-close="false">
            <div class="demo-wrap">
                <div class="dw-title flex-sbc">
                    <div class="dt-info">
                        <div class="title">预约演示</div>
                        <div class="tag">预约线上演示，更好了解系统功能</div>
                    </div>
                    <img class="dt-image" src="https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240511/b1e7f92b2147a15a3d3541a5ef2bb959.png" alt="有库云">
                </div>
                <div class="dw-c-box">
                    <div class="cb-title">联系信息</div>
                    <div class="cb-item">
                        <div class="i-title">姓名<span>*</span></div>
                        <div class="i-content">
                            <el-input v-model="info.name" placeholder="请输入姓名"></el-input>
                        </div>
                    </div>
                    <div class="cb-item">
                        <div class="i-title">电话<span>*</span></div>
                        <div class="i-content">
                            <el-input v-model="info.phone" placeholder="请输入电话"></el-input>
                        </div>
                    </div>
                </div>
                <div class="dw-btn">
                    <div class="btn flex-cc" @click="clickDemo">预约演示</div>
                    <div class="tip">点击“预约演示”，即表示我授权有库云通过我提供的联系信息与我联系，沟通预定演示时间及方式。</div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import api from '@/utils/common.js';
export default {
    props:{
        show:{ 
            type: Boolean,
            default: false
        },
    },
    data () {
        return {
            info: {
                name: '',
                phone: '',
            },
        }
    },
    onLoad () {

    },
    methods: {
        clickDemo(){
            if(this.info.name == ''){
                this.$message.error('请输入姓名')
                return
            }
            if(this.info.phone == ''){
                this.$message.error('请输入电话')
                return
            }
            let params = {
                name: this.info.name,
                phone: this.info.phone,
            }
            api.post('/api/common/user_reservation',params, (res) => {
                if(res.code == 200){
                    this.$message.success('已预约')
                    this.$emit('clickDemoClose')
                }else{
                    this.$message.error(res.msg)
                }
            });
        },
        clickDemoClose(){
            this.$emit('clickDemoClose')
        }
    },
}
</script>

<style lang='scss'>
.demonstrationPage{
    .demo-wrap{
        overflow: hidden;
        border-radius: 8px;
        .dw-title{
            background: #eff5fd;
            .dt-info{
                padding-left: 120px;
                .title{
                    font-size: 28px;
                    color: #101010;
                    font-weight: bold;
                }
                .tag{
                    font-size: 14px;
                    color: #5a5a5a;
                    padding-top: 10px;
                }
            }
            .dt-image{
                width: 170px;
                height: 110px;
                vertical-align: bottom;
            }
        }
        .dw-c-box{
            padding: 50px 120px;
            .cb-title{
                font-size: 16px;
                color: #101010;
                font-weight: bold;
            }
            .cb-item{
                .i-title{
                    font-size: 16px;
                    color: #101010;
                    padding: 20px 0;
                    span{
                        color: #ff0000;
                    }
                }
                .i-content{
                    .el-input{
                        border: none;
                        background: #f7f7f7;
                    }
                    .el-input__inner{
                        border: none;
                        background: #f7f7f7;
                        height: 45px;
                        border-radius: 4px;
                    }
                }
            }
        }
        .dw-btn{
            padding: 20px 120px;
            .btn{
                width: 100%;
                background: #0068ff;
                color: #fff;
                font-size: 16px;
                height: 45px;
                border-radius: 4px;
                cursor: pointer;
            }
            .tip{
                font-size: 13px;
                color: #5a5a5a;
                padding: 10px 0;
                line-height: 22px;
            }
        }
    }
}
</style>

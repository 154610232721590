<template>
    <div class="pcPrice">
        <div class="wp-tab-wrap">
            <div class="tw-title">
                <div class="title">基础版本，永久免费</div>
                <div class="tip">我们将永久免费开放基础版本，不限制Windows端、网页端及小程序端使用。并提供未来所有的版本更新。</div>
            </div>
            <div class="tw-tab flex-sbc">
                <div class="tw-t-item" v-for="(item,index) in tabList" :key="index">
                    <div class="title" >{{item.title}}</div>
                    <div class="content flex-coc">
                        <div class="tc-num">{{item.num}}</div>
                        <div class="tc-price" v-if="item.title == '基础版'"><span>{{item.price}}</span></div>
                        <div class="tc-price" v-else>￥<span>{{item.price}}</span>/年</div>
                        <div @click="isOpenShow = true" class="tc-btn flex-cc">立即开通PRO版</div>
                        <img class="tc-img" :src="item.image" alt="">
                    </div>
                </div>
            </div>
        </div>
        <!-- 开通pro -->
        <OpenProPage
            :show="isOpenShow"
            @clickCloseShow="isOpenShow = false"
        >
        </OpenProPage>
    </div>
</template>

<script>
import OpenProPage from "@/components/OpenProPage"
export default {
    components:{
        OpenProPage,
    },
    data () {
        return {
            isOpenShow: false,
            tabIndex: 0,
            tabList: [{
                title: '基础版',
                price: '免费',
                num: '共计1个账号空间',
                image: 'https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240801/3dbfb92d8d30b1140b77cedc783a3b5f.jpg'
            },{
                title: '3人团队版',
                price: '398',
                num: '共计4个账号空间',
                image: 'https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240511/5900199226d23467cb9178245983ecb6.png'
            },{
                title: '10人团队版',
                price: '998',
                num: '共计11个账号空间',
                image: 'https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240511/5900199226d23467cb9178245983ecb6.png'
            },{
                title: '无限账号版',
                price: '1598',
                num: '不限账号空间 ',
                image: 'https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240511/5900199226d23467cb9178245983ecb6.png'
            }],
        }
    },
    onLoad () {

    },
    methods: {

    },
}
</script>

<style scoped lang='scss'>
.pcPrice{
    height: calc(100vh - 70px);
    overflow-y: auto;
    .wp-tab-wrap{
        background: #eff5fd;
        padding: 60px 270px;
        .tw-t-item{
            margin-top: 30px;
            background: #fff;
            border-radius: 8px;
            width: calc((100% - 90px)/4);
            box-shadow: 0px 0px 10px 4px rgba(100, 100, 100, 0.1);
            .title{
                margin: 0 20px;
                padding: 15px 0;
                font-size: 20px;
                color: #101010;
                font-weight: bold;
                text-align: center;
                border-bottom: 1px solid #d7d7d7;
            }
            .content{
                padding: 0 15px;
                .tc-num{
                    font-size: 14px;
                    color: #7B7B7B;
                    padding: 25px;
                }
                .tc-price{
                    font-size: 12px;
                    color: #0068ff;
                    span{
                        font-size: 24px;
                        font-weight: bold;
                    }
                }
                .tc-btn{
                    cursor: pointer;
                    width: 100%;
                    height: 46px;
                    font-size: 14px;
                    color: #fff;
                    background: #3e6ae1;
                    border-radius: 4px;
                    margin: 25px 0;
                }
                .tc-img{
                    width: 100%;
                }
            }
        }
        .tw-title{
            .title{
                font-size: 40px;
                color: #101010;
                font-weight: bold;
                text-align: center;
            }
            .tip{
                width: 500px;
                font-size: 16px;
                color: #000;
                text-align: center;
                padding: 50px 0;
                margin: 0 auto;
                line-height: 28px;
            }
        }
    }
}
</style>

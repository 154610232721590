<!--
 * @Descripttion: 产品
 * @version: 
 * @Author: alex.yang
 * @Date: 2024-05-11 15:29:13
 * @LastEditors: alex.yang
 * @LastEditTime: 2024-07-30 15:21:28
-->
<template>
    <div class="pcProducts">
            <div class="wp-do-wrap">
                <div class="dw-title">安全、先进、上手无忧的保障服务</div>
                <div class="dw-box">
                    <div class="db-item">
                        <div class="title">让数据安全成为您持续发展的有力保障。</div>
                        <div class="content">您的数据存储在我们的云端服务器，会进行24/7持续安全监控。并且我们采用了高标准的加密技术来保护数据的安全性 。云服务遵循如ISO 27001、GDPR等行业标准和安全认证确保数据安全和隐私的合规性。</div>
                        <div class="cw-foot">
                            <div class="ph-f-btn flex-wc">
                                <div @click="isOpenShow = true" class="btn open flex-cc">免费开通</div>
                                <div @click="isDemoShow = true" class="btn flex-cc">免费预约演示</div>
                            </div>
                        </div>
                        <img class="icon" src="https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240515/5540bace8ef151edcf6cb68fc7bf9bf4.gif" alt="">
                    </div>
                    <div class="db-item" style="padding-top: 80px;background: #fff;">
                        <div class="title">始终创新技术，让其在业务场景中发挥更多可能性。</div>
                        <div class="content">在您使用有库云PRO版时，基于您使用现有功能的优化，以及可能使用过程您会提出的一些建设性需求，我们会积极跟进了解，尽可能完善您的建议，同时不收取费用。与此同时，我们也始终关注前沿技术的发展，希望通过与业务融合，进行创新升级，让其您的生意中发挥更大的作用。</div>
                        <div class="cw-foot">
                            <div class="ph-f-btn flex-wc">
                                <div @click="isOpenShow = true" class="btn open flex-cc">免费开通</div>
                                <div @click="isDemoShow = true" class="btn flex-cc">免费预约演示</div>
                            </div>
                        </div>
                        <img class="icon" src="https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240515/b679f9e2c5f4ec1263a37a25c6a84fc0.jpg" alt="">
                    </div>
                    <div class="db-item" style="padding-top: 80px;">
                        <div class="title">我们关注简洁、高效的使用体验，配套一对一培训让系统易上手。</div>
                        <div class="content">我们尽可能奉行极简主义设计功能及页面，让用户有轻松易上手，在培训上让用户降低学习成本同时，在您准备好产品资料、库存资料等信息后，我们将辅助您尽可能在短的时间投入经营使用。</div>
                        <div class="cw-foot">
                            <div class="ph-f-btn flex-wc">
                                <div @click="isOpenShow = true" class="btn open flex-cc">免费开通</div>
                                <div @click="isDemoShow = true" class="btn flex-cc">免费预约演示</div>
                            </div>
                        </div>
                        <img class="icon" src="https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240511/f4b550afbdcf51a7cf08141878d210f4.jpg" alt="">
                    </div>
                </div>
            </div>
            <div class="wp-question-wrap">
                <div class="qw-title">常见问题   Q&A</div>
                <div class="qu-content">
                    <el-collapse v-model="activeName" accordion>
                        <el-collapse-item :name="index"
                            v-for="(item,index) in question" :key="index">
                            <template slot="title">
                                <div class="title">{{item.title}}</div>
                            </template>
                            <div class="content">{{item.content}}</div>
                        </el-collapse-item>
                    </el-collapse>
                </div>
            </div>
        <div class="pp-f-tip">东有创新@     2024 Ukoocloud  粤ICP备2023127805号-1 </div>
        <!-- 开通pro -->
        <OpenProPage
            :show="isOpenShow"
            @clickCloseShow="isOpenShow = false"
        >
        </OpenProPage>
        <!-- 预约演示 -->
        <DemonstrationPage
            :show="isDemoShow"
            @clickDemoClose="isDemoShow = false"
        >
        </DemonstrationPage>
    </div>
</template>

<script>
import api from '@/utils/common.js';
import areaObj from '@/utils/area.json';
import OpenProPage from "@/components/OpenProPage"
import DemonstrationPage from "@/components/DemonstrationPage"
export default {
    components:{
        OpenProPage,
        DemonstrationPage,
    },
    data () {
        return {
            isOpenShow: false,
            isDemoShow: false,
            isRegisterTry: false,
            info: {},
            activeName: '',
            question: [{
                isShow: false,
                title: '软件系统是否可以不同设备间实现数据实时同步？',
                content: '有库云作为云进销存系统，数据存储在云端。只要设备有接入网络，不同设备即可实现数据实时同步。'
            },{
                isShow: false,
                title: '安装是否会占用手机或电脑设备大量内存？',
                content: '云服务的好处就是，基本数据运行、存储都通过云服务器来完成，并不占用太多本地存储空间，且始终运行流畅。'
            },{
                isShow: false,
                title: '版本账号空间数不够用，能否进行账号空间升级？',
                content: '可以的，账号空间可以选择匹配的有库云版本套餐进行升级，但需要注意的是版本无法降级。'
            },{
                isShow: false,
                title: '软件功能担心团队成员吃不透，用不起来这么办？',
                content: '有库云进销存非常简单、易上手，基本团队成员所要掌握的操作流程都极其简单，遵循本身的业务逻辑，同时我们也会认真耐心的进行培训讲解。但不论如何，最关键也是需要经营者的重视，引导鼓励大家改变，养成习惯。'
            },{
                isShow: false,
                title: '有库云能否在苹果电脑MAC上运行？',
                content: '有库云应用端有网页版、小程序版、Windows版，在苹果电脑上登录网页版即可兼容使用。'
            },{
                title: '使用有库云是否需要额外购买打印设备？',
                content: '通过电脑端连接打印的，无需另行购买打印设备，不论是针式打印机或喷墨打印机有库云都支持。若业务需要支持手机端WIFI远程无线打印的，则需要根据我们提供的配置另行购买。'
            },{
                isShow: false,
                title: '是否支持多仓库、多门店管理？',
                content: '有库云所有版本均支持多仓库、多门店的管理，不额外收取费用。'
            },{
                isShow: false,
                title: '打印销售单、出库单以及购销合同时能否自定义内容？',
                content: '销售单 、出库单备注信息以及收款二维码可以根据自身需求进行自定义。购销合同除商品信息之外的内容可根据合作细节进行自定义。'
            },{
                isShow: false,
                title: '开单时是否有不同客户历史价格记录？',
                content: '同商品不同时期采购价格不同，我们会根据采购价格波动实时计算商品加权平均成本，计算库存金额，确保准确性。'
            },{
                isShow: false,
                title: '我们有些员工负责多个岗位的内容，是否需要多个账号？',
                content: '不用，只需把该员工添加到有库云系统中，然后根据需要进行权限的分配即可。'
            },],
        }
    },
    mounted () {
    },
    methods: {
    
    },
}
</script>

<style lang='scss'>
.pcProducts{
    height: calc(100vh - 70px);
    width: 100%;
    overflow-y: auto;
    .wp-do-wrap{
        background: #f3f8ff;
        padding: 120px 0px 0 0px;
        .dw-title{
            font-size: 40px;
            color: #101010;
            text-align: center;
            font-weight: bold;
        }
        .dw-content{
            flex-wrap: wrap;
            padding: 40px 300px 0 300px;
            .dc-item{
                padding-bottom: 50px;
                width: calc((100% - 50px)/2);
                .icon{
                    width: 100px;
                    height: 100px;
                }
                .title{
                    font-size: 24px;
                    color: #101010;
                    font-weight: bold;
                    padding-top: 20px;
                    padding-bottom: 15px;
                }
                .content{
                    font-size: 16px;
                    color: #808080;
                    line-height: 25px;
                    text-align: center;
                }
            }
        }
        .dw-box{
            padding-top: 30px;
            .db-item{
                .title{
                    font-size: 24px;
                    color: #000;
                    font-weight: bold;
                    padding: 50px 250px 0 250px;
                }
                .content{
                    width: 750px;
                    font-size: 16px;
                    color: #808080;
                    line-height: 28px;
                    text-align: justify;
                    padding: 20px 250px;
                }
                .cw-foot{
                    padding: 20px 0 50px 230px;
                    .ph-f-btn{
                        .btn{
                            width: 265px;
                            height: 40px;
                            border-radius: 4px;
                            background: #f2f2f2;
                            color: #101010;
                            font-size: 14px;
                            cursor: pointer;
                            margin: 0 20px;
                        }
                        .open{
                            background: #3e6ae1;
                            color: #fff;
                        }
                    }
                }
                .icon{
                    vertical-align: bottom;
                    width: 100%;
                }
            }
        }
    }
    .wp-question-wrap{
        padding: 100px 0;
        .qw-title{
            font-size: 40px;
            color: #101010;
            font-weight: bold;
            text-align: center;
            padding-bottom: 50px;
        }
        .qu-content{
            padding: 30px 270px;
            .title{
                line-height: 22px;
                font-size: 16px;
                color: #000;
            }
            .content{
                font-size: 16px;
                color: #808080;
            }
        }
    }
    .pp-foot{
        height: 120px;
        .pp-f-btn{
            padding-top: 20px;
            .btn{
                width: 265px;
                height: 40px;
                border-radius: 4px;
                background: #f4f4f4;
                color: #101010;
                font-size: 14px;
                cursor: pointer;
                margin: 0 20px;
            }
            .open{
                background: #3e6ae1;
                color: #fff;
            }
        }
        .pp-f-tip{
            color: #7b7b7b;
            font-size: 12px;
            text-align: center;
            padding-top: 20px;
        }
    }
    .pp-f-tip{
        color: #7b7b7b;
        font-size: 12px;
        text-align: center;
        padding: 15px 0;
        background: #101010;
    }
}
</style>

<!--
 * @Descripttion: 
 * @version: 
 * @Author: alex.yang
 * @Date: 2024-05-11 14:16:12
 * @LastEditors: alex.yang
 * @LastEditTime: 2024-08-14 14:10:53
-->
<template>
    <div class="pc-index-wrap">
        <div class="hw-title flex-sbc">
            <div class="t-left flex-wc">
                <!-- <div style="font-size: 20px;
                    color: #101010;
                    cursor: pointer;
                    font-weight: bold;" @click="current = 3">佛山市东有创新科技有限公司</div> -->
                <img @click="current = 3" class="tl-logo" src="https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240801/d8e849c43bc60e989dd39ff787ab284c.png" alt="">
                <div class="tl-title flex-wc">
                    <div class="title" :class="current == index?'active':''"
                        @click="clickTitle(index)"
                        v-for="(item,index) in titleList" :key="index">
                        <div v-if="index != 1">{{item}}</div>
                        <a style="text-decoration: none;color: #4e5969;" class="ab" v-else >{{item}}</a>
                    </div>
                </div>
            </div>
            <div class="t-right">
                <img class="logo" src="https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240511/e67d3671835aaedf99c9631d006ac7ca.png" alt="">
            </div>
        </div>
        <div class="news-tip-wrap flex-fec">
            <el-popover
                v-model="isDrawerNews"
                width="200"
                placement="top-start"
                trigger="hover">
                <template #default>
                    <div class="p-phone-wrap flex-sbc">
                        <img class="ppw-img" src="https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240513/68d3193d8f603342d05c763982fa5d66.png" alt="">
                        <a class="ppw-info" href="tel:400-895-7887">
                            <div class="name">咨询电话</div>
                            <div class="phone">400-895-7887</div>
                        </a>
                    </div>
                </template>
                <template #reference>
                    <img @click="isDrawerNews = true" class="logo" src="https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240513/f6a923568022cacd592a0776f49fa804.gif" alt="">
                </template>
            </el-popover>
        </div>
        <!-- <div class="news-tip-wrap" @click="isDrawerNews = true">
            <img class="logo" src="https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240513/f6a923568022cacd592a0776f49fa804.gif" alt="">
        </div> -->
        <!-- 消息 -->
        <!-- <el-drawer
            size="85%"
            :with-header="false"
            direction='btt'
            custom-class="drawer-news-wrap"
            :visible.sync="isDrawerNews">
            <div class="news-wrap">
                <div class="nw-title flex-sbc">
                    <img class="logo" src="https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240509/4a38157246c66c15f0ad3870206ff7ec.png" alt="">
                    <div class="icon" @click="isDrawerNews = false">
                        <i class="el-icon-minus"></i>
                    </div>
                </div>
                <div class="nw-tip">请您在沟通中遵循当地法律法规，有库云工作人员将竭答您的问题</div>
                <div class="nw-box">
                    <div class="nw-question">有库云智能客服为您服务！请问有什么可以帮到您？</div>
                    <div class="nw-question">
                        <div class="nq-title">常见问题   Q&A</div>
                        <div class="nq-item">软件是否是不同电脑数据实时同步的？</div>
                        <div class="nq-item">安装是否会占用手机或电脑设备大量内存？</div>
                        <div class="nq-item">版本人员数不够用，能否进行账号空间升级？</div>
                        <div class="nq-item">软件功能担心团队成员吃不透，用不起来怎么办？</div>
                        <div class="nq-item">是否支持多个门店的开单管理、不同种类的商品管理？</div>
                        <div class="nq-item">打印销售单、出库单以及购销合同时内容能否自定义？</div>
                        <div class="nq-item">开单时是否有不同客户历史价格记录？</div>
                        <div class="nq-item">我们有些员工负责多个岗位的事情，是否需要多个账号？</div>
                    </div>
                </div>
                <div class="nw-input">
                    <el-input placeholder="输入信息" v-model="keyword">
                        <template slot="append">
                            <div class="btn">
                                <img class="img" src="https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240511/9f5d57e05cca860039ae8feac8a3b62e.png" alt="">
                            </div>
                        </template>
                    </el-input>
                </div>
            </div>
        </el-drawer> -->
        <div class="hw-content">
            <div v-if="current == 3">
                <PcHome></PcHome>
            </div>
            <div v-if="current == 1">
                <pcPrice></pcPrice>
            </div>
            <div v-if="current == 0">
                <PcProduct></PcProduct>
            </div>
            <div v-if="current == 2">
                <PcAboutUs></PcAboutUs>
            </div>
        </div>
    </div>
</template>

<script>
import PcHome from "./pcHome.vue"
import PcProduct from "./pcProduct.vue"
import PcAboutUs from "./pcAbout.vue"
import pcPrice from "./pcPrice.vue"
export default {
    components:{
        PcHome,
        PcProduct,
        PcAboutUs,
        pcPrice,
    },
    data () {
        return {
            current: 3,
            titleList: ['服务','价格','关于我们'],
            isDrawerNews: false,
        }
    },
    onLoad () {

    },
    methods: {
        clickTitle(index){
            // if(index != 1){
                this.current = index
            // }
        }
    },
}
</script>

<style lang='scss'>
.pc-index-wrap{
    .news-tip-wrap{
        position: fixed;
        top: 50%;
        right: 0px;
        transform: translate(-50%,0);
        z-index: 999;
        .logo{
            width: 50px;
            height: 50px;
            cursor: pointer;
        }
    }
    .hw-title{
        position: sticky;
        top: 0;
        left: 0;
        height: 70px;
        background: #fff;
        padding: 0 270px;
        z-index: 1024;
        box-shadow: 0px 0px 5px 2px rgba(100, 100, 100, 0.05);
        .t-left{
            .tl-logo{
                width: 105px;
                height: 27px;
                cursor: pointer;
            }
            .tl-title{
                margin-left: 50px;
                .title{
                    font-size: 14px;
                    color: #101010;
                    padding: 10px 20px;
                    cursor: pointer;
                }
                .title:hover{
                    background: #f0f0f0;
                    border-radius: 4px;
                }
            }
            .active{
                background: #f0f0f0;
                border-radius: 4px;
            }
        }
        .t-right{
            .logo{
                width: 100px;
                height: 20px;
            }
        }
    }
    .hw-content{
        height: calc(100vh - 70px);
    }
    .drawer-news-wrap{
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        overflow: hidden;
        .news-wrap{
            padding: 30px 20px 20px 20px;
            .nw-title{
                padding: 20px 0;
                .logo{
                    width: 120px;
                    height: 30px;
                }
                .icon{
                    cursor: pointer;
                    .el-icon-minus{
                        font-size: 28px;
                        color: #101010;
                        font-weight: bold;
                    }
                }
            }
            .nw-tip{
                font-size: 12px;
                color: #5a5a5a;
                padding-bottom: 20px;
            }
            .nw-box{
                height: calc(100vh - 390px);
                overflow-y: auto;
            }
            .nw-question{
                font-size: 14px;
                color: #000;
                background: #f7f7f7;
                border-radius: 6px;
                padding: 20px;
                margin-bottom: 20px;
                .nq-title{
                    font-size: 16px;
                    color: #101010;
                    font-weight: bold;
                    margin-bottom: 10px;
                }
                .nq-item{
                    cursor: pointer;
                    font-size: 14px;
                    text-align: justify;
                    padding: 6px 0;
                    color: #7b7b7b;
                    line-height: 28px;
                }
            }
            .nw-input{
                border-radius: 6px;
                border: 1px solid #DCDFE6;
                .btn{
                    height: 100%;
                    .img{
                        width: 25px;
                        height: 25px;
                        vertical-align: bottom;
                        cursor: pointer;
                    }
                }
                .el-input__inner{
                    height: 50px;
                    border: none;
                }
                .el-input-group__append{
                    border: none;
                    background: rgba(0, 0, 0, 0);
                }
            }
        }
    }
}
.el-popper{
    margin-right: 20px;
    border-radius: 10px;
}
.p-phone-wrap{
    text-decoration: none;
    .ppw-img{
        width: 50px;
        height: 50px;
    }
    .ppw-info{
        text-decoration: none;
        width: calc(100% - 70px);
        .name{
            font-size: 16px;
            color: #4e5969;
        }
        .phone{
            padding-top: 5px;
            font-size: 14px;
            color: #4e5969;
        }
    }
    .ab{
        text-decoration: none;
        color: #4e5969;
    }
}

</style>s

<template>
    <div class="pc-home-wrap">
        <div class="box-c-wrap">
            <div class="ph-content flex-sb">
                <div class="pc-left">
                    <img class="logo" src="https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240729/aaadd51a66aa928ad8377a3e39a752db.png" alt="有库云进销存">
                    <div class="title">心中有数，决策有据，有条有理有库云。</div>
                    <div class="tip">个体小微企业经营规范、提高效率、控制成本方面的智能助理。</div>
                    <img class="img" src="https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240729/baffb1f3266ce888761df719080c416e.png" alt="有库云进销存">
                    <div class="l-btn flex-wc">
                        <div class="item">
                            <a style="text-decoration: none;color: #4e5969;" class="ab flex-wc" :href="downloadLink" download>
                                <img class="img" src="https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240729/2309bab8a9befa831fcce5bad5f51ee8.png" alt="有库云进销存">
                                <div class="text">下载Windows版</div>
                            </a>
                        </div>
                        <div class="btn flex-cc" @click="getHome">登录网页端</div>
                        <div class="btn icon flex-cc">
                            <div>登录小程序端</div>
                            <div class="img-p flex-coc">
                                <img class="imgs" src="https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240718/52aa456b805d7d17ece35260ff14eec2.jpg" alt="">
                                <div class="p">微信扫码进入有库云小程序</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pc-right">
                    <img class="image" src="https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240729/5d13fe93b566c9c54ba42f075dc13712.png" alt="有库云进销存">
                </div>
            </div>
            <div class="ph-foot">
                <div class="ph-f-btn flex-cc">
                    <div @click="isOpenShow = true" class="btn open flex-cc">免费开通</div>
                    <div @click="isDemoShow = true" class="btn flex-cc">免费预约演示</div>
                </div>
            </div>
        </div>
        <div class="content-wrap">
            <div class="cw-title">有库云能为您做什么</div>
            <div class="cw-tip">销售规范、库存精准、账目清晰、数据全面这些生意中</div>
            <div class="cw-tip">至关重要的管理因素的优化，是我们始终坚持的服务。</div>
            <div class="cw-box flex-cc">
                <div class="cw-b-item">
                    <img class="image" src="https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240729/268c8737e212a64765c9a2aa5137edc7.png" alt="有库云进销存">
                    <div class="content flex-coc">
                        <div class="title">规范销售流程</div>
                        <div class="title">提升销售效率</div>
                        <div class="active">通过自动化销售流程，减少人工干预避免错误和延误，实现快速响应客户求。</div>
                    </div>
                </div>
                <div class="cw-b-item">
                    <img class="image" src="https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240729/4a3730641082eae19f1e7e9b1415c35c.png" alt="有库云进销存">
                    <div class="content flex-coc">
                        <div class="title">精细库存管理</div>
                        <div class="title">优化库存成本</div>
                        <div class="active">利用先进的库存管理算法，实时监控库存水平，预测需求，减少过剩或缺货风险，有效降低库存成本。</div>
                    </div>
                </div>
                <div class="cw-b-item">
                    <img class="image" src="https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240729/3b0fb9890595273057982d650c637a4b.png" alt="有库云进销存">
                    <div class="content flex-coc">
                        <div class="title">全面清晰记账</div>
                        <div class="title">避免账目混乱</div>
                        <div class="active">提供详尽的记账功能，确保每一笔交易都有迹可循，账目清晰，便于审计和财务分析，避免财务混乱。</div>
                    </div>
                </div>
                <div class="cw-b-item">
                    <img class="image" src="https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240729/401f7654e4c7c6ddb587a5cb99c44354.png" alt="有库云进销存">
                    <div class="content flex-coc">
                        <div class="title">实时生成报表</div>
                        <div class="title">及时发现问题</div>
                        <div class="active">系统能够实时生成各种财务和业务报表，帮助管理者快速把握经营状况，及时发现并解决潜在问题。</div>
                    </div>
                </div>
            </div>
            <div class="cw-foot">
                <div class="ph-f-btn flex-cc">
                    <div @click="isOpenShow = true" class="btn open flex-cc">免费开通</div>
                    <div @click="isDemoShow = true" class="btn flex-cc">免费预约演示</div>
                </div>
            </div>
        </div>
        <div class="content-wrap detail-box">
            <div class="cw-list flex-cc">
                <div class="cl-left">
                    <img v-show="saleIndex == 0" class="image" src="https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240729/7968d14b0532c3746203ef7722b65aea.png" alt="有库云进销存">
                    <img v-show="saleIndex == 1" class="image" src="https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240731/65a8316e2d626c0e4a1d101035306aa2.png" alt="有库云进销存">
                    <img v-show="saleIndex == 2" class="image" src="https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240731/a8df06bbff222faadf50d4545fabd65b.png" alt="有库云进销存">
                    <img v-show="saleIndex == 3" class="image" src="https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240731/1996a8c7ee6a43d2fb7463411dedb14b.png" alt="有库云进销存">
                    <img v-show="saleIndex == 4" class="image" src="https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240731/cd86646e59ed2e6078e1c2e3c815f0aa.png" alt="有库云进销存">
                </div>
                <div class="cl-right" style="padding-left: 60px;">
                    <div class="cr-title">
                        <div class="title">规范销售流程</div>
                        <div class="title">提升销售效率</div>
                    </div>
                    <div class="cr-tag flex-wc" @click="saleIndex = index"
                        v-for="(item,index) in saleList" :key="index">
                        <div style="width: 25px">
                            <img v-if="saleIndex == index" class="icon" src="https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240729/7e807d6a53237d2936fb3177e0fed124.png" alt="有库云进销存">
                        </div>
                        <div class="tag" :class="saleIndex == index?'active':''">
                            {{item}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="cw-foot">
                <div class="ph-f-btn flex-cc">
                    <div @click="isOpenShow = true" class="btn open flex-cc">免费开通</div>
                    <div @click="isDemoShow = true" class="btn flex-cc">免费预约演示</div>
                </div>
            </div>
        </div>
        <div class="content-wrap detail-box" style="background: #fff;">
            <div class="cw-list flex-cc">
                <div class="cl-right" style="margin-right: 60px">
                    <div class="cr-title">
                        <div class="title">精细库存管理</div>
                        <div class="title">优化库存成本</div>
                    </div>
                    <div class="cr-tag flex-wc" @click="ckIndex = index"
                        v-for="(item,index) in kcList" :key="index">
                        <div style="width: 25px">
                            <img v-if="ckIndex == index" class="icon" src="https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240729/7e807d6a53237d2936fb3177e0fed124.png" alt="有库云进销存">
                        </div>
                        <div class="tag" :class="ckIndex == index?'active':''">
                            {{item}}
                        </div>
                    </div>
                </div>
                <div class="cl-left">
                    <img v-show="ckIndex == 0" class="image" src="https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240731/c3405fe7d7396f9cac26b86e29c6599f.png" alt="有库云进销存">
                    <img v-show="ckIndex == 1" class="image" src="https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240731/a0ac44ee4720bc769a8a3b70adb44ebc.png" alt="有库云进销存">
                    <img v-show="ckIndex == 2" class="image" src="https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240731/82c81a414014cec92b0f055212f1d1dc.png" alt="有库云进销存">
                    <img v-show="ckIndex == 3" class="image" src="https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240731/1331536bf88c9e7f4e5572cd5e18494d.png" alt="有库云进销存">
                </div>
            </div>
            <div class="cw-foot">
                <div class="ph-f-btn flex-cc">
                    <div @click="isOpenShow = true" class="btn open flex-cc">免费开通</div>
                    <div @click="isDemoShow = true" class="btn flex-cc">免费预约演示</div>
                </div>
            </div>
        </div>
        <div class="content-wrap detail-box">
            <div class="cw-list flex-cc">
                <div class="cl-left">
                    <img v-show="jzIndex == 0" class="image" src="https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240730/fe9b34529891dc769b1279a6c77ea488.png" alt="有库云进销存">
                    <img v-show="jzIndex == 1" class="image" src="https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240731/e6b1e1bbf09fe0f80f257616355a1ead.png" alt="有库云进销存">
                    <img v-show="jzIndex == 2" class="image" src="https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240731/f80008a9c1356c8ea1cf0903623576f4.png" alt="有库云进销存">
                    <img v-show="jzIndex == 3" class="image" src="https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240731/561d3fc8ffe4f50caa5352007a7b4990.png" alt="有库云进销存">
                </div>
                <div class="cl-right" style="padding-left: 60px;">
                    <div class="cr-title">
                        <div class="title">全面清晰记账</div>
                        <div class="title">避免账目混乱</div>
                    </div>
                    <div class="cr-tag flex-wc" @click="jzIndex = index"
                        v-for="(item,index) in jzList" :key="index">
                        <div style="width: 25px">
                            <img v-if="jzIndex == index" class="icon" src="https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240729/7e807d6a53237d2936fb3177e0fed124.png" alt="有库云进销存">
                        </div>
                        <div class="tag" :class="jzIndex == index?'active':''">
                            {{item}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="cw-foot">
                <div class="ph-f-btn flex-cc">
                    <div @click="isOpenShow = true" class="btn open flex-cc">免费开通</div>
                    <div @click="isDemoShow = true" class="btn flex-cc">免费预约演示</div>
                </div>
            </div>
        </div>
        <div class="content-wrap detail-box" style="background: #fff;">
            <div class="cw-list flex-cc">
                <div class="cl-right" style="margin-right: 60px">
                    <div class="cr-title">
                        <div class="title">实时生成报表</div>
                        <div class="title">及时发现问题</div>
                    </div>
                    <div class="cr-tag flex-wc" @click="bbIndex = index"
                        v-for="(item,index) in bbList" :key="index">
                        <div style="width: 25px">
                            <img v-if="bbIndex == index" class="icon" src="https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240729/7e807d6a53237d2936fb3177e0fed124.png" alt="有库云进销存">
                        </div>
                        <div class="tag" :class="bbIndex == index?'active':''">
                            {{item}}
                        </div>
                    </div>
                </div>
                <div class="cl-left">
                    <img v-show="bbIndex == 0" class="image" src="https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240730/176f7de631ae1559172117281288c07c.png" alt="有库云进销存">
                    <img v-show="bbIndex == 1" class="image" src="https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240731/8975c5394baf7793a76a8c890d2a443c.png" alt="有库云进销存">
                </div>
            </div>
            <div class="cw-foot">
                <div class="ph-f-btn flex-cc">
                    <div @click="isOpenShow = true" class="btn open flex-cc">免费开通</div>
                    <div @click="isDemoShow = true" class="btn flex-cc">免费预约演示</div>
                </div>
            </div>
        </div>
        <div class="wp-do-wrap">
            <div class="dw-title">智能革新，让卓越管理触手可及</div>
            <div class="dw-box">
                <div class="db-item">
                    <div class="title">多端互联，云端数据同步，高效协同管理。</div>
                    <div class="content">有库云作为云进销存软件，可实现数据在多端实时同步，可利用微信小程序登录有库云移动端，也可通过电脑端应用支持Windows系统、MacOS系统以及网页端进行登录操作，并且支持大量的数据存储及备份。让经营者及员工不受设备限制，高效地进行业务管理。</div>
                    <div class="cw-foot">
                        <div class="ph-f-btn flex-wc">
                            <div @click="isOpenShow = true" class="btn open flex-cc">免费开通</div>
                            <div @click="isDemoShow = true" class="btn flex-cc">免费预约演示</div>
                        </div>
                    </div>
                    <img class="icon" src="https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240515/22a6cb55ecb089a60a6fbaf3778bef1f.png" alt="">
                </div>
                <div class="db-item" style="padding-top: 80px;background: #fff">
                    <div class="title">订单自动化，实现高效简洁的流程。</div>
                    <div class="content">实现订单处理自动化，包括订单处理出库和入库及记账，减少手动操作，提高订单处理速度。</div>
                    <div class="cw-foot">
                        <div class="ph-f-btn flex-wc">
                            <div @click="isOpenShow = true" class="btn open flex-cc">免费开通</div>
                            <div @click="isDemoShow = true" class="btn flex-cc">免费预约演示</div>
                        </div>
                    </div>
                    <img class="icon" src="https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240511/04cd0c22479b1049ef6a4266f63827f9.png" alt="">
                </div>
                <div class="db-item" style="padding-top: 80px">
                    <div class="title">业务智能和数据分析，让数据驱动决策。</div>
                    <div class="content">提供强大的数据分析工具，帮助用户从销售和库存数据中获得洞察，支持数据驱动的决策。</div>
                    <div class="cw-foot">
                        <div class="ph-f-btn flex-wc">
                            <div @click="isOpenShow = true" class="btn open flex-cc">免费开通</div>
                            <div @click="isDemoShow = true" class="btn flex-cc">免费预约演示</div>
                        </div>
                    </div>
                    <img class="icon" src="https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240511/3cc3c0ef48e26c4a5cd2ff684aaf73ea.png" alt="">
                </div>
            </div>
        </div>
        <div class="ph-f-tip">东有创新@     2024 Ukoocloud  粤ICP备2023127805号-1 </div>
        <!-- 开通pro -->
        <OpenProPage
            :show="isOpenShow"
            @clickCloseShow="isOpenShow = false"
        >
        </OpenProPage>
        <!-- 预约演示 -->
        <DemonstrationPage
            :show="isDemoShow"
            @clickDemoClose="isDemoShow = false"
        >
        </DemonstrationPage>
    </div>
</template>

<script>
import api from '@/utils/common.js';
import OpenProPage from "@/components/OpenProPage"
import DemonstrationPage from "@/components/DemonstrationPage"
export default {
    components:{
        OpenProPage,
        DemonstrationPage,
    },
    data () {
        return {
            isOpenShow: false,
            isDemoShow: false,
            downloadLink: 'https://api.ukoocloud.com/api/common/get_shop_exe',
            saleList: [
                '快速识别历史客户价格，提高开单效率',
                '自动关联订单应收，方便查询回款',
                '订单设置审核，无过审不出库',
                '出库单自动引用订单，协同仓库高效出货',
                '支持单据、合同快速生成打印',
            ],
            saleIndex: 0,
            kcList: [
                '商品库存根据业务实时统计，了解库存情况',
                '商品实现批次管理，更精准的把控库存出入',
                '支持多仓调拨，满足仓库业务需求',
                '设置商品预警，避免销售缺货',
            ],
            ckIndex: 0,
            jzList: [
                '自动汇总待结算订单，不漏记错记',
                '自定义添加记账类型，出入账目全面记录',
                '业务进项票、销项票发票管理，避免报税漏报',
                '支持对账单批量合并结算记账，高效快捷记账',
            ],
            jzIndex: 0,
            bbList: [
                '实时汇总数据进行统计分析，了解经营情况',
                '快速生成报表，及时了解经营细节',
            ],
            bbIndex: 0,
        }
    },
    mounted () {
        
    },
    methods: {
        getHome(){
            window.open('https://store.ukoocloud.com/', '_blank');
        }
    },
}
</script>

<style lang='scss'>
.pc-home-wrap{
    height: calc(100vh - 70px);
    width: 100%;
    overflow-y: auto;
    .box-c-wrap{
        height: 850px;
        position: relative;
        background: url('../../assets/home_background.png') no-repeat top center;
		background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
    .ph-content{
        position: absolute;
        top: 25%;
        left: 250px;
        .pc-left{
            flex: 1;
            .logo{
                width: 360px;
                height: 62px;
            }
            .title{
                font-size: 40px;
                color: #000;
                font-weight: bold;
                padding: 20px 0;
            }
            .tip{
                color: #7d7d7d;
                font-size: 18px;
            }
            .img{
                width: 505px;
                height: 70px;
                padding-top: 40px;
            }
            .l-btn{
                .item{
                    cursor: pointer;
                    .ab{
                        text-decoration: none;
                        color: #4e5969;
                    }
                    .img{
                        width: 40px;
                        height: 40px;
                        margin-right: 15px;
                    }
                    .text{
                        font-size: 20px;
                        color: #0068ff;
                        font-weight: bold;
                        margin-top: 35px;
                    }
                }
                .btn{
                    padding: 5px 5px;
                    border-radius: 6px;
                    cursor: pointer;
                    margin-left: 20px;
                    margin-top: 35px;
                    width: 110px;
                    height: 45p;
                    border: 1px solid #000;
                    position: relative;
                    .img-p{
                        display: none;
                        position: absolute;
                        bottom: 50px;
                        width: 180px;
                        height: 180px;
                        background: #fff;
                        border-radius: 6px;
                        padding: 20px;
                        text-align: center;
                        box-shadow: 0px 0px 10px 4px rgba(100, 100, 100, 0.1);
                        .imgs{
                            width: 150px;
                            height: 150px;
                        }
                        .p{
                            font-size: 13px;
                            padding-top: 10px;
                        }
                    }
                }
                .icon:hover{
                    .img-p{
                        display: block;
                    }
                }
            }
        }
        .pc-right{
            flex: 1;
            .image{
                width: 100%;
                height: auto;
                margin-top: -60px;
            }
        }
    }
    .ph-foot{
        position: absolute;
        bottom: 50px;
        left: 0;
        right: 0;
        .ph-f-btn{
            .btn{
                width: 265px;
                height: 40px;
                border-radius: 4px;
                background: #f2f2f2;
                color: #101010;
                font-size: 14px;
                cursor: pointer;
                margin: 0 20px;
            }
            .open{
                background: #3e6ae1;
                color: #fff;
            }
        }
        // .ph-f-tip{
        //     color: #7b7b7b;
        //     font-size: 12px;
        //     text-align: center;
        //     padding-top: 20px;
        // }
    }
    .content-wrap{
        height: 850px;
        position: relative;
        .cw-title{
            font-size: 46px;
            color: #101010;
            font-weight: bold;
            text-align: center;
            margin-top: 140px;
            margin-bottom: 20px;
        }
        .cw-tip{
            font-size: 16px;
            color: #101010;
            text-align: center;
            padding: 5px 0;
        }
        .cw-box{
            margin-top: 50px;
            .cw-b-item{
                width: 300px;
                height: 400px;
                background: #f3f8ff;
                margin: 30px 16px;
                border-radius: 10px;
                cursor: pointer;
                overflow: hidden;
                .image{
                    width: 100%;
                    height: 150px;
                    vertical-align: bottom;
                }
                .content{
                    height: calc(100% - 150px);
                    justify-content: center;
                    .title{
                        font-size: 18px;
                        color: #143d79;
                        padding: 5px 0;
                        font-weight: bold;
                        letter-spacing: 2px;
                        display: block;
                    }
                    .active{
                        display: none;
                        font-size: 16px;
                        color: #000;
                        padding: 0 40px;
                        line-height: 26px;
                        text-align: justify;
                    }
                }
            }
            .cw-b-item:hover{
                box-shadow: 0px 0px 10px 4px rgba(100, 100, 100, 0.1);
                .content{
                    background: #fff;
                    .title{
                        display: none;
                    }
                    .active{
                        display: block;
                    }
                }
            }
        }
        .cw-foot{
            position: absolute;
            bottom: 50px;
            left: 0;
            right: 0;
            .ph-f-btn{
                .btn{
                    width: 265px;
                    height: 40px;
                    border-radius: 4px;
                    background: #f2f2f2;
                    color: #101010;
                    font-size: 14px;
                    cursor: pointer;
                    margin: 0 20px;
                }
                .open{
                    background: #3e6ae1;
                    color: #fff;
                }
            }
        }
    }
    .detail-box{
        background: #f3f8ff;
        .cw-list{
            padding: 160px 200px;
            .cl-left{
                flex: 1;
                .image{
                    width: 100%;
                    height: auto;
                }
            }
            .cl-right{
                // flex: 1;
                .cr-title{
                    font-size: 46px;
                    color: #101010;
                    font-weight: bold;
                    .title{
                        padding-bottom: 10px;
                    }
                }
                .cr-tag{
                    margin-top: 40px;
                    .icon{
                        vertical-align: bottom;
                        width: 25px;
                        height: 25px;
                    }
                    .tag{
                        font-size: 20px;
                        color: #B2B2B2;
                        margin-left: 10px;
                        margin-top: -5px;
                        cursor: pointer;
                    }
                    .active{
                        color: #000;
                    }
                }
                .cr-tip{
                    .tip{
                        font-size: 20px;
                        color: #B2B2B2;
                        padding-top: 20px;
                    }
                }
            }
        }
    }
    .wp-do-wrap{
        background: #f3f8ff;
        padding: 120px 0px 0 0px;
        .dw-title{
            font-size: 40px;
            color: #101010;
            text-align: center;
            font-weight: bold;
        }
        .dw-box{
            padding-top: 30px;
            .db-item{
                .title{
                    font-size: 24px;
                    color: #000;
                    font-weight: bold;
                    padding: 50px 250px 0 250px;
                }
                .content{
                    width: 750px;
                    font-size: 16px;
                    color: #808080;
                    line-height: 28px;
                    text-align: justify;
                    padding: 20px 250px;
                }
                .cw-foot{
                    padding: 20px 230px;
                    .ph-f-btn{
                        .btn{
                            width: 265px;
                            height: 40px;
                            border-radius: 4px;
                            background: #f2f2f2;
                            color: #101010;
                            font-size: 14px;
                            cursor: pointer;
                            margin: 0 20px;
                        }
                        .open{
                            background: #3e6ae1;
                            color: #fff;
                        }
                    }
                }
                .icon{
                    vertical-align: bottom;
                    width: 100%;
                }
            }
        }
    }
    .el-dialog{
        margin: 0;
        margin-top: 0vh !important;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        padding-bottom: 10px;
        border-radius: 10px;
        .el-dialog__header{
            padding: 0 !important;
        }
    }
    .ph-f-tip{
        color: #7b7b7b;
        font-size: 12px;
        text-align: center;
        padding: 15px 0;
        background: #101010;
    }
}
</style>

<template>
    <div class="open-pro-wrap">
        <el-dialog
            :visible.sync="show"
            width="600px"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :show-close="false">
            <!-- 开通PRO版 -->
            <div class="open-wrap">
                <div class="ow-title flex-wc" @click="clickCloseShow">
                    <i class="el-icon-arrow-left"></i>
                    <div class="title">开通有库云</div>
                </div>
                <div class="ow-content">
                    <div class="ow-c-item">
                        <div class="ci-tit flex-wc">
                            <div class="title">套餐选择</div>
                        </div>
                        <div class="ci-box flex-sbc" style="flex-wrap: wrap;">
                            <div class="item flex-cc" :class="currentIndex == index?'active':''"
                                v-for="(item,index) in renewList" :key="index"
                                @click="clickRenew(item,index)">
                                {{item.name}}
                            </div>
                        </div>
                    </div>
                    <div class="ow-c-item">
                        <div class="ci-tit flex-wc">
                            <div class="title">账号注册</div>
                            <div class="tag">*</div>
                        </div>
                        <div class="ci-box flex-sbc">
                            <el-input size="large" v-model="openInfo.phone" style="width: 300px"
                                placeholder="请输入注册开通有库云账号手机号码" />
                        </div>
                    </div>
                    <div class="ow-c-item">
                        <div class="ci-tit flex-wc">
                            <div class="title">验证码</div>
                            <div class="tag">*</div>
                        </div>
                        <div class="ci-box flex-wc">
                            <el-input size="large" style="width: 300px" v-model="openInfo.code" placeholder="请输入验证码" />
                            <el-button style="width: 120px;margin-left: 20px" 
                                size="large" type="primary" plain :disabled="countdown > 0"
                                @click="getOpenCode">
                                {{ countdown > 0 ? countdown + '秒重新获取' : '获取验证码' }}
                            </el-button>
                        </div>
                        <div class="ci-tip">注册后，首次登录通过验证码登录后进行账号密码设置</div>
                    </div>
                </div>
                <div class="price-wrap flex-sbc">
                    <div class="pw-left flex-sbc">
                        <div class="item">
                            <div class="tip">订阅年费</div>
                            <div class="price" v-if="activate_package_id == 0">免费</div>
                            <div class="price" v-else>￥{{renewPrice}}/年</div>
                        </div>
                    </div>
                    <div class="btn flex-cc" @click="getRegister">开通</div>
                    <!-- <el-button color="#0068ff" size="large" type="primary" >开通</el-button> -->
                </div>
            </div>
        </el-dialog>
        <!-- 扫码支付 -->
        <el-dialog
            :visible.sync="isPriceCodeShow"
            width="240"
            :show-close="false">
            <div class="price-code flex-coc">
                <img width="140" height="140" class="img" :src="payImg" alt="">
                <div class="tip">微信扫码支付</div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import areaObj from '@/utils/area.json';
import api from '@/utils/common.js';
export default {
    props:{
        show:{ 
            type: Boolean,
            default: false
        },
    },
    data () {
        return {
            openState: 1,
            info: {
                province: '',
                city: '',
                area: '',
            },
            openInfo: {
                name: '',
                phone: '',
                code: '',
                invitation_code: ''
            },
            provinceArr: [],
            cityArr: [],
            areaArr: [],
            countdown: 0,
            currentIndex: 0,
            renewList: [],
            renewPrice: '',
            activate_package_id: '',
            payImg: '',
            order_number: '',
            isPriceCodeShow: false,
            timer: null,
            counter: 0
        }
    },
    mounted () {
        this.provinceArr = Object.keys(areaObj)
        this.getActivatePackage()
    },
    methods: {
        clickCloseShow(){
            this.$emit('clickCloseShow')
        },
        // 获取支付状态
        getPayStatus(){
            let params = {
                order_number: this.order_number
            }
            api.post('/api/login/pay_status',params, (res) => {
                if(res.data.status === 1){
                    this.$message.success('支付成功')
                    this.openState = 1
                    this.isPriceCodeShow = false
                    this.isCodeShow = false
                    clearInterval(this.timer); 
                    window.open('https://store.ukoocloud.com/', '_blank');
                }else{
                    this.counter++; 
                    if (this.counter >= 30) { 
                        clearInterval(this.timer); 
                        ElMessage({message: '支付失败',type: 'warning'});
                    }
                }
            });
        },
        // 去支付
        getRegister(){
            let params = {
                phone: this.openInfo.phone,
                code: this.openInfo.code,
                activate_package_id: this.activate_package_id
            };
            api.post('/api/login/register',params, (res) => {
                if(this.activate_package_id == 0){
                    this.$message.success('开通成功')
                    window.open('https://store.ukoocloud.com/', '_blank');
                }else{
                    this.payImg = res.data.qr_code
                    this.order_number = res.data.order_number
                    this.isPriceCodeShow = true
                    this.timer = setInterval(() => {
                        this.getPayStatus();
                    }, 2000);
                }
            });
        },
        // 选择套餐
        clickRenew(item,index){
            this.currentIndex = index;
            this.activate_package_id = item.id;
            this.renewPrice = item.price;
        },
        // 获取套餐
        getActivatePackage(){
            api.get('/api/login/get_activate_package_whole', {}, (res) => {
                this.renewList = res.data.list;
                this.activate_package_id = this.renewList[0].id;
                this.renewPrice = this.renewList[0].price;
            });
        },
        // 选择省
        changeProvince(){
            this.cityArr = Object.keys(areaObj[this.info.province])
            this.info.city = ''
            this.info.area = ''
        },
        // 选择市
        changeCity(){
            console.log();
            this.areaArr = areaObj[this.info.province][this.info.city]
            this.info.area = ''
        },
        // 获取验证码
        getOpenCode(){
            this.openInfo.code = ''
            if(this.openInfo.phone == ''){
                this.$message.error('请输入注册开通有库云账号手机号码')
                return
            }
            if (this.countdown > 0) {
                return; 
            }
            // 发送获取验证码的请求
            api.get('/api/login/get_register_vif', {phone: this.openInfo.phone}, (res) => {
                this.countdown = 60;
                this.$message.success(res.msg)
                const timer = setInterval(() => {
                    this.countdown--;
                    if (this.countdown <= 0) {
                        clearInterval(timer); 
                    }
                }, 1000);
            });
        },
        // 下一步
        clickNext(){
            if(this.openInfo.name == ''){
                this.$message({message: '请输入企业名称',type: 'error'});
                return
            }
            if(this.openInfo.phone == ''){
                this.$message({ message: '请输入注册账户',type: 'error' });
                return
            }
            if(this.openInfo.code == '' && this.state != 1){
                this.$message({message: '请输入验证码', type: 'error'});
                return
            }
            this.openState = 2
        },
    },
}
</script>

<style lang='scss'>
.open-pro-wrap{
    .open-wrap{
        .ow-title{
            padding: 20px 30px 20px 30px;
            cursor: pointer;
            .el-icon-arrow-left{
                font-size: 18px;
                color: #101010;
                font-weight: bold;
            }
            .title{
                font-size: 16px;
                color: #101010;
                font-weight: bold;
                margin-left: 5px;
            }
        }
        .ow-content{
            padding: 0 30px 30px 30px;
            .ow-c-item{
                .ci-tit{
                    font-size: 15px;
                    color: #101010;
                    .tag{
                        color: #ff0000;
                    }
                }
                .ci-box{
                    padding: 15px 0;
                    .titel{
                        font-size: 14px;
                        padding-right: 10px;
                    }
                    .el-select{
                        margin-right: 10px;
                    }
                    .el-select:last-child{
                        margin-right: 0;
                    }
                    .item{
                        width: calc((100% - 20px)/2);
                        height: 80px;
                        background: #eff5ff;
                        border-radius: 6px;
                        font-size: 16px;
                        color: #101010;
                        cursor: pointer;
                        margin-bottom: 20px;
                        font-weight: bold;
                    }
                    .active{
                        background: #0068ff;
                        color: #fff;
                    }
                }
                .ci-tip{
                    margin-top: -5px;
                    font-size: 12px;
                    color: #969799;
                }
            }
        }
        .ow-tip{
            font-size: 12px;
            color: #b8b8b8;
            padding-left: 30px;
            margin-top: -10px;
        }
        .ow-btn{
            padding-bottom: 40px;
            .btn{
                cursor: pointer;
                width: 100px;
                height: 40px;
                border-radius: 4px;
                font-size: 16px;
                color: #fff;
                background: #0068ff;
            }
        }
        .price-wrap{
            // background: #f5f5f5;
            border-top: 1px solid #ddd;
            padding: 20px 40px;
            margin-bottom: -10px;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
            .pw-left{
                .item{
                    .price{
                        font-size: 20px;
                        color: #101010;
                        font-weight: bold;
                        padding-top: 5px;
                    }
                    .tag{
                        font-size: 12px;
                        padding-top: 10px;
                    }
                }
            }
            .btn{
                width: 80px;
                height: 36px;
                background: #0068ff;
                border-radius: 4px;
                font-size: 15px;
                color: #fff;
                cursor: pointer;
            }
        }
    }
    .price-code{
        padding: 20px;
        .tip{
            font-size: 14px;
            padding-top: 10px;
        }
    }
}
</style>
